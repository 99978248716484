import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3441252649/src/src/templates/faq/index.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`MID`}</h2>
    <h3>{`What is a MID (Merchant Identifier)?`}</h3>
    <p>{`Let's start by defining what a Merchant ID is:`}</p>
    <i>"A unique identification number sequence associated with a physical or online merchant location, typically allowing payment processors involved in a transaction to know where to send which funds. It’s effectively an address within the payment ecosystem. Without a merchant ID, the networks involved won't know where to send the merchant's money."</i>
    <p>{`To put it simply a merchant ID or (MID) is Merchant Identification Number.`}</p>
    <p>{`This is a unique code provided to a merchant by their payment processor. Often abbreviated as MID, this code is transmitted along with the cardholder information to involved parties for transaction reconciliation.`}</p>
    <h2>{`Locate a MID`}</h2>
    <h3>{`Where can you locate a Merchant ID? 👀`}</h3>
    <p>{`Merchants can typically find their merchant identification number on their billing statement from their payment processor. If a client has a close relationship with their merchants and feel comfortable doing so they can point them toward their payment processor and ask them to locate their MID, explaining that having it will expedite the onboarding process with Fidel. Another place merchant have access to their MID is within their payment processor's online merchant portal.`}</p>
    <p>{`Different Card Brands use unique MIDs.`}</p>
    <p>{`Mastercard and Visa refer to this unique number as the MID.`}</p>
    <p>{`Amex's equivalent to a MID is called a Service Establishment Number.`}</p>
    <h2>{`MIDs format`}</h2>
    <h3>{`What do MIDs look like?`}</h3>
    <p>{`MIDS or Merchant ID’S are not issued by Fidel API and therefore we do not have any control over the format of these identifiers. There is no real uniformity with MIDS but there are some general rule of thumbs we can take away:`}</p>
    <ul>
      <li parentName="ul">{`No less than 5 chars or no longer than 15 chars;`}</li>
      <li parentName="ul">{`Can certainly be alphanumeric for example Stripe issues MIDS which are usually alphanumeric strings;`}</li>
      <li parentName="ul">{`In the case of Payment Facilitators, expect multiple merchants to be using the same one.`}</li>
    </ul>
    <h2>{`Payment Processor`}</h2>
    <h3>{`What is a Payment Processor? 🧾`}</h3>
    <p>{`The payment processor (like Stripe, SumUp etc.) manages the card transaction process by acting as the mediator between the Merchant and the financial institutions involved.`}</p>
    <h5>{`Merchant Payment flow`}</h5>
    <img src="https://docs.fidel.uk/assets/images/merchant-payment-flow.png" alt="Merchant Payment flow" />
    <p>{`When you insert, tap, swipe or even type your card number in to pay for something that triggers the creation of an auth, (short for authorization).`}</p>
    <p>{`The merchant's point of sale (POS) software sends this auth through their payment processor who acts as an intermediary for the merchant's payment software system to access the credit card networks and be able to accept credit cards as a form payment.`}</p>
    <p>{`The auth is a payment event that sends a quick call for verification through to the issuing bank to see if the card is valid (not expired, lost or stolen) and that it has funds associated with it.`}</p>
    <p>{`This request goes to the credit card's issuing bank (where the cardholder got the line of credit from) and returns instantly either an "approve" or "decline" response that is sent back through the payment processor to the merchant's payment software.`}</p>
    <p>{`This entire process happens in just a matter of seconds. If the card gets approved and the transaction gets finalized and settled then the payment processor deposits the funds from that credit card transaction into the merchant's bank account.`}</p>
    <p>{`As complex as this process is, it makes the ability to pay for things very convenient. In our increasingly digital world not accepting credit cards is like turning away money from people willing to spend it.`}</p>
    <p>{`Helping Merchants Find their Payment Processor:`}</p>
    <ul>
      <li parentName="ul">{`To figure out who is processing their credit card payments merchants can look at their deposits that fund from their credit card transactions. In the description of the deposit it should identify who sent the funds. The funds will be sent from the payment processor the merchant is using.`}</li>
      <li parentName="ul">{`The payment processor is the keeper of the Merchant Identification Number or MID. 🔒`}</li>
      <li parentName="ul">{`The payment processor charges the merchants regular fees to be able to accept credit cards on behalf of themselves and the card brands. If a merchant has their statement regarding processing rates and fees for credit card transactions that statement should be from their payment processor and the MID (sometimes called merchant number) should be printed on it.`}</li>
    </ul>
    <h3>{`Why is a MID important?`}</h3>
    <p>{`When a Merchant consents to allow us to access their MIDs it gives us the ability to return higher levels of accuracy in the data we provide to our clients. Many times locations can be complex to track due to situations like malls or shopping centers where one physical street address can be the site of twenty, fifty or even hundreds of different individual stores.`}</p>
    <p>{`MIDs become more valuable when it comes to specific locations that may contain thousands of transactions occurring in one day at one address, but thanks to the transactions going through specific MIDs they are safely connected to the store where the purchase was made.`}</p>
    <p>{`A store with both a physical and online locations will have different MIDs for each. The transactions processed online are running through an entirely different MID than those processed within the physical store. This further breaks down the data accurately to specify where the transaction actually occurred.`}</p>
    <p>{`Providing a MID during onboarding with Fidel can help to guarantee high match rates for transactions and locations, even in locations with high-turnover rates, and even on the busiest days of the year. Providing a MID during onboarding can also reduce the onboarding of the merchant to a single day.`}</p>
    <h3>{`Is it secure for me to share MIDs with Fidel?`}</h3>
    <p>{`At Fidel we understand that a MID is a very sensitive piece of information. We handle data compliant to PCI DSS regulations. We tokenize all of our data and assign it unassociated equivalent data elements that act as a reference point to the original data. The resulting outcome is a 'token' that disguises the original data but still allows the necessary information to communicate across and back to our clients securely.`}</p>
    <p>{`At Fidel we use tokenization in many areas within our APIs, including how we send and receive transactional data in real-time with the major card networks. The MIDs are received by Fidel via our encrypted closed-loop API, which tokenizes the MIDs before they are collated into our secure dashboard.`}</p>
    <center>🔐</center>
    <p>{`Fidel passes MIDs directly to our network partners who have their own encryption processes for secure file transfers. Regardless of where the MID goes from there it's securely concealed in encryption language as the transaction data tokens we produce. The card network's own secure information transfer process further ensures that the information throughout its whole journey remains secure. Whenever that information is within our API, it is unreadable to anyone outside that circuit. Fidel makes a point of requesting that any information sent to us is done so via our API or SDKs to make sure that it's captured securely.`}</p>
    <h2>{`Location Sync failed`}</h2>
    <h3>{`How do I resolve a location that has failed to sync?`}</h3>
    <p>{`In order to successfully onboard the location/s the answer to why it has failed to onboard will typically guide your approach in terms of resolution steps.`}</p>
    <h3>{`How can I programmatically set a location to syncing once it has been created via the API?`}</h3>
    <p>{`In order to sync a location at its creation, you are required to submit the below parameter. Failing to do this will result in the location reverting to an idle state and will mean that we don’t send the data to the card networks for onboarding unless you manually navigate to the dashboard to trigger the sync.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{ "status": "syncing" }
`}</code></pre>
    <p>{`Additional information can be found in the API Reference `}<a parentName="p" {...{
        "href": "https://reference.fidel.uk/reference/create-location"
      }}>{`here`}</a>{`.`}</p>
    <h2>{`Locations stopped tracking`}</h2>
    <h3>{`I have received reports that a location has stopped tracking, how do I restart this?`}</h3>
    <p>{`There’s a number of reasons why this may have occurred, below are some information we will require to investigate:`}</p>
    <ul>
      <li parentName="ul">{`Has the location ever tracked a transaction? This is key as if the answer is no then it is likely that the MID was incorrect.`}</li>
      <li parentName="ul">{`When was the last time the location successfully tracked a transaction?`}</li>
      <li parentName="ul">{`Were there missing transactions from this location? If so, when is the transaction dated at. Not all merchants are set up to accept auth transactions, therefore a good rule of thumb is to wait 3-5 working days to allow a potential clearing to be received.`}</li>
    </ul>
    <p>{`The most common reason for a location to stop tracking is the presence of a new MID/VSID (Visa Store Identifier), in a real world scenario this occurs when a merchant decides to use a new processing facility.`}</p>
    <p>{`Please raise a support ticket with our `}<a parentName="p" {...{
        "href": "mailto:support@fidel.uk"
      }}>{`support@fidel.uk`}</a>{` for any further help.`}</p>
    <h2>{`New MID`}</h2>
    <h3>{`I’ve obtained a new MID for one of my merchant locations, how can I add it?`}</h3>
    <p>{`There are a couple of ways to achieve this either via the Dashboard or through the API.`}</p>
    <p><strong parentName="p">{`Note`}</strong>{`: A location must not be in sync on any card scheme before the below actions can be taken.`}</p>
    <h4>{`Dashboard`}</h4>
    <ul>
      <li parentName="ul">{`Click the three dots icon on the far right which is present next to each individual location.
`}<img parentName="li" {...{
          "src": "https://docs.fidel.uk/assets/images/locations-dashboard.png",
          "alt": "Locations",
          "title": "Locations"
        }}></img></li>
      <li parentName="ul">{`Select the edit option
`}<img parentName="li" {...{
          "src": "https://docs.fidel.uk/assets/images/locations-dashboard-menu.png",
          "alt": "Locations action menu",
          "title": "Locations action menu"
        }}></img></li>
      <li parentName="ul">{`On the widget that appears click on the Include a MID button. At this point you will be able to add your MID.
`}<img parentName="li" {...{
          "src": "https://docs.fidel.uk/assets/images/include-mid-location.png",
          "alt": "Include a MID",
          "title": "Include a MID"
        }}></img></li>
      <li parentName="ul">{`When updating a location it will always revert to an idle state. It is critical that you trigger the changes by clicking on Sync Locations at the top right of the screen.
`}<img parentName="li" {...{
          "src": "https://docs.fidel.uk/assets/images/edit-location-2.png",
          "alt": "Edit location",
          "title": "Edit location"
        }}></img></li>
    </ul>
    <h4>{`API`}</h4>
    <p>{`A developer can programmatically add a MID by following the below:`}</p>
    <ul>
      <li parentName="ul">{`Call updateLocation endpoint - Update Location`}</li>
      <li parentName="ul">{`You will need to add the same attributes as previous i.e. if the merchant address has not changed then the developer is required to submit that same information.`}</li>
      <li parentName="ul">{`You should also add the searchBy field with the value being the new MID`}</li>
      <li parentName="ul">{`As before, the user is required to sync the locations in order to trigger the changes. In addition they will also be required to go back to the dashboard and press Sync Locations.`}</li>
    </ul>
    <h2>{`Shared MIDs`}</h2>
    <h3>{`Does Fidel support ‘Shared MIDs’?`}</h3>
    <p>{`All merchants who process transactions using a Payfac (Payment Facilitators) use a MID that is also used to process transactions by many other merchants.`}</p>
    <p>{`Using a shared MID affects the ability to track transactions as shared MIDs do not always have unique identifiers which are necessary to differentiate which merchant locations the transactions were made at.`}</p>
    <p>{`If a MID that is shared across multiple merchants was added to a single location then that location would track every transaction made at all locations sharing that MID. This would cause transactions that don’t belong to the business onboarded to be tracked to it. 😥`}</p>
    <p>{`In addition, merchant consent is required for the ability to track a merchant’s transactions. As such, tracking transactions on a shared MID that doesn’t just belong to the merchant who has provided consent raises additional concerns surrounding merchant consent.`}</p>
    <p>{`Fortunately, at Fidel API we have been able to distinguish additional identifiers with the card networks which do allow us to track most Payfac transactions, at least in the clearing or settlement state.`}</p>
    <p>{`Below is a breakdown of what which Payfacs we can track in North America on which card networks:`}</p>
    <table className="table">
    <tr>
        <th style={{
          "paddingLeft": "1rem"
        }}>Payment Events</th>
        <th>Visa</th>
        <th>MasterCard</th>
        <th>Amex</th>
    </tr>
    <tr>
        <td style={{
          "paddingLeft": "1rem"
        }}>Authorizations</td>
        <td>✅</td>
        <td>🚫</td>
        <td>🚫</td>
    </tr>
    <tr>
        <td style={{
          "paddingLeft": "1rem"
        }}>Clearing/Settlements</td>
        <td>✅</td>
        <td>✅</td>
        <td>✅</td>
    </tr>
    </table>
    <p>{`Even with the progress we have made in tracking PayFacs in North America there are still a few Payfacs which are complete blockers as they do not contain unique identifiers to be able to parse out and track transactions specific to one location.`}</p>
    <p>{`Below is a list of Payfacs in North America we can and cannot currently track at this time.`}</p>
    <p><strong parentName="p">{`Note`}</strong>{`: while the list may not include every North American Payfac, it contains most major Payfacs we have encountered significant processing volume through.`}</p>
    <table className="table">
    <tr>
        <th style={{
          "paddingLeft": "1rem"
        }}>Payment Facilitator</th>
        <th>Fidel API's ability to track transactions</th>
    </tr>
    <tr>
        <td style={{
          "paddingLeft": "1rem"
        }}>Square</td>
        <td>✅</td>
    </tr>
    <tr>
        <td style={{
          "paddingLeft": "1rem"
        }}>Shopify</td>
        <td>✅</td>
    </tr>
    <tr>
        <td style={{
          "paddingLeft": "1rem"
        }}>Toast</td>
        <td>✅</td>
    </tr>
    <tr>
        <td style={{
          "paddingLeft": "1rem"
        }}>Stripe</td>
        <td>✅</td>
    </tr>
    <tr>
        <td style={{
          "paddingLeft": "1rem"
        }}>PayPal</td>
        <td>🚫</td>
    </tr>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      